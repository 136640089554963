import React, { useState } from 'react';
import { Link } from 'react-router-dom';
  
function Footer() {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };
  const handleScroll = (event, selector) => {
    setTimeout(() => {
        const targetElement = document.querySelector(selector);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }, 1000);
};
    return (
        <>
<footer className="footer-top">
  <div className="container">
    <div className="row">
      {/* Left Part */}
      <div className="col-lg-6 col-md-12">
        <div className="footer-box">
          <div className="row">
            <div className="col-lg-4 footer-divider">
              <div className="logo-container">
                <img src="img/logo.png" alt="Logo" />
              </div>
              <p className="footer-text">
                <span className="our-broking">OUR BROKING</span>
                <span className="services-llp">Services LLP</span>
              </p>
            </div>
            <div className="col-md-4 footer-right-col footer-divider">
              <p className="footer-address">
                <strong>Address:</strong> <br />
                Room no-561, LSE building, Firoze Gandhi Market, Ludhiana,
                Punjab-141001
              </p>
            </div>
            <div className="col-md-4 footer-right-col">
              <p className="footer-contact-info">
                <strong>Phone:</strong> <br />
                0161-4072791
                <br />
                <strong>Email:</strong>
                <br />
                <Link to="mailto:wecare@ourbroking.com">wecare@ourbroking.com</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Right Part */}
      <div className="col-lg-6 col-md-12">
        <div className="row">
          <div className="col-md-4 footer-right-col">
            <h5 style={{ color: "black" }}>COMPANY</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  className="home-link"
                  to="/#home"
                  onClick={(e) => handleScroll(e, '#home')}
                  style={{ color: "black" }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className="about-link"
                  to="/#about"
                  onClick={(e) => handleScroll(e, '#about')}
                  style={{ color: "black" }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  className="services-link"
                  to="/#services"
                  onClick={(e) => handleScroll(e, '#services')}
                  style={{ color: "black" }}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  className="contact-link"
                  to="./Contact"
                  style={{ color: "black" }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4 footer-right-col">
            <h5 style={{ color: "black" }}>QUICK LINKS</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  to="https://www.bseindia.com"
                  target="_blank" rel="noreferrer"
                  style={{ color: "black" }}
                >
                  BSE
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.nseindia.com"
                  target="_blank" rel="noreferrer"
                  style={{ color: "black" }}
                >
                  NSE
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.sebi.gov.in/"
                  target="_blank" rel="noreferrer"
                  style={{ color: "black" }}
                >
                  SEBI
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.scores.gov.in/scores/complaintRegister.html"
                  target="_blank" rel="noreferrer"
                  style={{ color: "black" }}
                >
                  SCORES
                </Link>
              </li>
              <li>
                <Link
                  to="https://smartodr.in/login"
                  target="_blank" rel="noreferrer"
                  style={{ color: "black" }}
                >
                  SMART ODR
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4 footer-right-col">
            <h5 style={{ color: "black" }}>INVESTORS</h5>
            <ul className="list-unstyled">
            <li>
                <Link
                  className="evoting-link"
                  to="https://evoting.cdslindia.com/Evoting/EvotingLogin"
                  target="_blank" rel="noreferrer"
                  style={{ color: "black" }}
                >
                  E-Voting
                </Link>
              </li>
              <li>
                <Link
                  className="faq-link"
                  to="/Faq"
                  style={{ color: "black" }}
                >
                  FAQ's
                </Link>
              </li>
              <li>
                <Link
                  className="Privacy_policy-link"
                  to="/Privacy_policy"
                  style={{ color: "black" }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  className="tnc-link"
                  to="/Tnc"
                  style={{ color: "black" }}
                >
                  Terms &amp; Conditions
                </Link>
              </li>
              <li>
                <Link
                  className="investor-link"
                  to="/Investor"
                  style={{ color: "black" }}
                >
                  Investor Charter
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="container aos-init aos-animate demo-content"
    data-aos="zoom-in"
  >
    <div className="row">
      <hr />
      <div className="text-center text-lg-start">
        <div className="disclaimer-section">
          <h6
            style={{
              color: "rgb(20, 18, 18)",
              fontSize: 18,
              fontWeight: 700,
              marginBottom: 5
            }}
          >
            Disclaimer
          </h6>
          <p style={{ fontSize: 14, fontWeight: 400, color: "grey" }}>
            Investment in securities market is subject to market risks, read all
            the related documents carefully before investing. OUR BROKING
            SERVICES LLP : Member of NSE &amp; BSE – SEBI Registration no.:
            INZ000309758, DP ID(CDSL)- 12098900, Exchange Registration Nos : NSE Trading Member Code -
            90314 | NSE Clearing Member Code - M70057 | BSE Clearing Number -
            6801 Registered Address: OUR BROKING SERVICES LLP - Room 561, LSE
            Building, Firoze Gandhi Market, Ludhiana - 141001, Punjab, India.
            For any complaints/grievances pertaining to securities broking,
            please write to &nbsp;
            <Link
              to="mailto:wecare@ourbroking.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              wecare@ourbroking.com
            </Link>
            , for DP related queries write to &nbsp;
            <Link
              to="mailto:dp@ourbroking.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              dp@ourbroking.com
            </Link>
            . Please ensure you carefully read the Risk Disclosure Document as
            prescribed by SEBI.
          </p>
        </div>
        <div className="attention-investors-section">
          <h6
            style={{
              color: "rgb(21, 20, 20)",
              fontSize: 18,
              fontWeight: 700,
              marginTop: 20
            }}
          >
            Attention Investors
          </h6>
          <div
            style={{
              color: "#050532",
              fontWeight: 400,
              fontSize: 14,
              marginTop: 5
            }}
            className="wrapper"
          >
            <ol className={isActive ? "active" : ""} style={{ color: "grey" }}>
              <li>
                Stock Brokers can accept securities as margin from clients only
                by way of pledge in the depository system w.e.f. September 1,
                2020.
              </li>
              <li>
                Update your mobile number &amp; email Id with your stock
                broker/depository participant and receive OTP directly from
                depository on your email id and/or mobile number to create
                pledge.
              </li>
              <li>
                Pay minimum 20% upfront margin of the transaction value to trade
                in the cash market segment.
              </li>
              <li>
                Investors may please refer to the Exchange's Frequently Asked
                Questions (FAQs) issued vide circular reference NSE/INSP/45191
                dated July 31, 2020, and NSE/INSP/45534 dated August 31, 2020,
                and other guidelines issued from time to time in this regard.
              </li>
              <li>
                Check your Securities /MF/ Bonds in the consolidated account
                statement issued by NSDL/CDSL every month.
              </li>
              <li>
                No need to issue cheques by investors while subscribing to IPO.
                Just write the bank account number and sign in the application
                form to authorize your bank to make payment in case of
                allotment. No worries for refund as the money remains in the
                investor's account.
              </li>
              <li>
                {" "}
                Message from Exchange(s)/Depositories | Issued in the interest
                of investors: Prevent Unauthorized Transactions in your
                demat/trading account --&gt; Update your Mobile Number/email ID
                with your stock broker/Depository Participant. Receive
                information of your transactions directly from Exchanges on your
                mobile/email at the end of the day and alerts on your registered
                mobile for all debits and other important transactions in your
                demat account directly from NSDL/CDSL on the same day. - Issued
                in the interest of investors.
              </li>
              <li>
                KYC is one-time exercise while dealing in securities markets -
                once KYC is done through a SEBI registered intermediary (broker,
                DP, Mutual Fund, etc.), you need not undergo the same process
                again when you approach another intermediary. (As instructed by
                SEBI, We hereby declare that we do engage in proprietary
                trading.)
              </li>
              <li>
                Filing Complaints on{" "}
                <Link
                  to="https://scores.gov.in/scores/Welcome.html"
                  style={{ color: "blue", textDecoration: "underline" }}
                  target="_blank" rel="noreferrer"
                >
                  SCOREs
                </Link>{" "}
                - Easy &amp; quick: a. Register on SCORES portal b. Mandatory
                details for filing complaints on SCORES: i. Name, PAN, Address,
                Mobile Number, Email ID c. Benefits: i. Effective communication
                ii. Speedy redressal of the grievances.
              </li>
            </ol>
            <span>
              <button
                className={`toggle_btn ${isActive ? "active" : ""}`}
                onClick={handleToggle}
                style={{
                  backgroundColor: "grey",
                  border: "none",
                  borderRadius: 5,
                  padding: "8px 15px"
                }}
              >
                <span
                  className="toggle_text"
                  style={{ color: "white", fontSize: 14, fontWeight: 700 }}
                  >
                  {isActive ? "Show Less" : "Show More"}
                </span>
                <span className="arrow">
                  <i className="fas fa-angle-down" aria-hidden="true" />
                </span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  2023 @ OUR BROKING. All rights reserved
</footer>

  </>);
}

export default Footer;