import React from 'react';
import { Link } from "react-router-dom";
function Header() {
    const handleScroll = (event, selector) => {
        setTimeout(() => {
            const targetElement = document.querySelector(selector);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 1000);
    };
    return ( <nav className="navbar navbar-expand-lg py-3 fixed-top bg-light">
    <div className="container">
        <Link className="navbar-brand" to="/">
            <img className="logo" src="img/logo.gif" alt="Logo" width="60" height="60"/>
            OUR BROKING
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
                <li className="nav-item px-3">
                    <Link className="nav-link home-link" to="/#home" onClick={(e) => handleScroll(e, '#home')}>Home</Link>
                </li>
                <li className="nav-item px-3">
                    <Link className="nav-link services-link" to="/#services" onClick={(e) => handleScroll(e, '#services')}>Product & Services</Link>
                </li>
                <li className="nav-item px-3">
                    <Link className="nav-link about-link" to="/#about" onClick={(e) => handleScroll(e, '#about')}>About Us</Link>
                </li>
                <li className="nav-item px-3">
                    <Link className="nav-link contact-link" to="/Contact">Contact Us</Link>
                </li>
            </ul>
            <button type="button" className="btn btn-warning ms-lg-3 px-3">Join Us</button>
        </div>
    </div>
 </nav> );
}

export default Header;