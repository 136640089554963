import './App.css';
import Header from './component/Header';
import Footer from './component/Footer';
import Front from './component/Front';
import Contact from './component/Contact';
import Faq from './component/Faq';
import Invest from './component/Investor';
import Privacy_policy from './component/Privacy_policy';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Tnc from './component/Tnc';
import { useEffect } from 'react';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <MainContent />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

function MainContent() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route exact path="/" element={<Front />} />
      <Route exact path="/Contact" element={<Contact />} />
      <Route exact path="/Faq" element={<Faq />} />
      <Route exact path="/Privacy_policy" element={<Privacy_policy />} />
      <Route exact path="/Investor" element={<Invest />} />
      <Route exact path="/Privacy_policy" element={<Privacy_policy />} />
      <Route exact path="/Tnc" element={<Tnc />} />
    </Routes>
  );
}

export default App;
