import React from 'react'
import { Link } from 'react-router-dom';
function Front() {
    return ( <>
        <section id="home" style={{ padding: 0 }}>
          <div style={{ marginTop: "8.7%" }} />
          <div style={{ marginTop: "8.7%" }} />
          {/* Hero Section */}
          <section id="hero" className="hero vh-80 d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-1 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay={200}
                  style={{ textAlign: "justify" }}
                >
                  <h1>Building your financial legacy hand in hand</h1>
                  <h2 style={{ color: "#ffc107" }}>
                    Making your money work for you, and your dreams come true
                  </h2>
                  <div
                    className="d-flex justify-content-center justify-content-lg-start"
                    style={{ padding: 5 }}
                  >
                    <Link
                      to="#about"
                      className="btn btn-warning scrollto"
                      style={{ padding: 20, fontSize: 20, fontWeight: 700 }}
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-6 order-1 order-lg-2 hero-img"
                  data-aos="zoom-in"
                  data-aos-delay={200}
                >
                  <img
                    src="img/hand_in_hand.png"
                    className="img-fluid animated"
                    alt="Hand in Hand"
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="services" className="team">
            <div className="container">
              <div className="heading">
                <h1>Product and Services</h1>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                  <div className="member" data-city="ET">
                    <img src="img/stoc.png" alt="" />
                    <h4>Equity Trading</h4>
                    <Link to="#ET" onclick="myFunction('ET')" className="myBtn">
                      Know more
                    </Link>
                    <p>
                      <span className="dots" />
                      <span className="more">
                        The purchase and sale of a company's stock listed on exchanges
                        such as the BSE/NSE is referred to as equity or stock trading.
                        Discipline and comprehensive review/detailed analysis can help
                        you succeed in this market segment. This is accomplished by
                        opening a trading account with a SEBI-registered stock broker.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                  <div className="member" data-city="DT">
                    <img src="img/derivative.png" alt="" />
                    <h4>Derivatives Trading</h4>
                    <Link to="#DT" onclick="myFunction('DT')" className="myBtn">
                      Know more
                    </Link>
                    <p>
                      <span className="dots" />
                      <span className="more">
                        Trading in financial products like futures and options that
                        derive their value from the underlying asset is referred to as
                        "derivative trading." Given that it is a margin-based product
                        and provides tremendous space for the development of
                        speculation and hedging, it is one of the most fascinating
                        forms of trading.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                  <div className="member" data-city="CD">
                    <img src="img/currency.jpg" alt="" />
                    <h4>Currency Derivatives</h4>
                    <Link to="#CD" onclick="myFunction('CD')" className="myBtn">
                      Know more
                    </Link>
                    <p>
                      <span className="dots" />
                      <span className="more">
                        One of the biggest investment marketplaces in the world, the
                        currency market, or Forex(FX), uses the buying and selling of
                        currencies as its primary trading method. While trading
                        currencies, a trader may exchange one currency for another to
                        make a profit or for security. Considering the high volatility
                        of the forex market, hedging is crucial to protect the
                        investor's position from an unfavorable change in exchange
                        rates.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch">
                  <div className="member" data-city="DS">
                    <img src="img/depository.jpg" alt="" />
                    <h4>Depository Services</h4>
                    <Link to="#DS" onclick="myFunction('DS')" className="myBtn">
                      Know more
                    </Link>
                    <p>
                      <span className="dots" />
                      <span className="more">
                        Depository services aid in the electronic storage of all
                        securities such as stocks, bonds, mutual funds, ETFs, or other
                        financial instruments that hold value and can be traded
                        between parties. The investor must open an account with the
                        depository through a depository participant in order to use
                        these services. Holding shares in the account is akin to
                        holding money in the bank.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="about" className="about">
            <div
              className="container aos-init aos-animate"
              data-aos="fade-up"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: 30,
                borderRadius: 10
              }}
            >
              <h1 style={{ color: "black", fontSize: 35, fontWeight: 900 }}>
                Unveiling <span className="visionaries">Our Visionaries</span>
              </h1>
              <div className="row">
                <div className="col-lg-4">
                  <div className="infographic">
                    <div className="icon">
                      <i className="fas fa-eye" />
                    </div>
                    <h2>Vision</h2>
                    <p style={{ textAlign: "justify", padding: 10 }}>
                      Step into the realm of OUR BROKING – Where innovation and
                      prosperity converge. Brace yourself for a revolution in the
                      world of financial growth and freedom.
                    </p>
                    <div className="full-content">
                      <p>
                        At OUR BROKING, we don't just simplify investing; we amplify
                        it. Our mission is to make the wonders of the capital market
                        accessible to everyone, igniting the spark of possibility in
                        each investor's heart.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 my-2">
                  <div className="infographic mission">
                    <div className="icon">
                      <i className="fas fa-rocket" />
                    </div>
                    <h2>Mission</h2>
                    <p style={{ textAlign: "justify", padding: 10 }}>
                      What sets us apart is not just our expertise but our unwavering
                      commitment to you, our esteemed partner. We believe that every
                      dream deserves the chance to materialize, and we empower you
                      with the tools and knowledge to shape your financial destiny.
                    </p>
                    <div className="full-content">
                      <p>
                        Embark on a journey through our user-friendly platform, where
                        a treasure trove of investment options, from stocks to
                        currency, awaits your discovery. With our team of seasoned
                        experts by your side, equipped with personalized guidance and
                        unwavering support, you're never alone in your investment
                        decisions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 my-2">
                  <div className="infographic values">
                    <div className="icon">
                      <i className="fas fa-hands-helping" />
                    </div>
                    <h2>Values</h2>
                    <p style={{ textAlign: "justify", padding: 10 }}>
                      Every financial journey is unique, and so are our strategies.
                      Immerse yourself in bespoke investment solutions tailored
                      precisely to your goals and risk appetite, each step taken with
                      your aspirations in mind.
                    </p>
                    <div className="full-content">
                      <p>
                        Transparency is the bedrock of our operations. Real-time
                        market data and portfolio management tools keep you in the
                        loop, empowering you with the knowledge you deserve throughout
                        your investment journey.
                      </p>
                      <p>
                        We are not just supporters; we are your mentors. Unwrap
                        regular updates and educational resources, our gifts to you,
                        encompassing the latest market trends and investment insights,
                        positioning you at the forefront of financial wisdom.
                      </p>
                      <p>
                        Welcome to a partnership that transcends boundaries, OUR
                        BROKING, where your financial future is not just a priority;
                        it's our passion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 
          <section id="Contact">
      <section class="demat-sixth">
        <div class="container-940">
        <div class="co-info-parent">
        <div class="co-info">
        <p class="download">1 Cr+</p>
        <p class="download-txt">Registered Clients</p>
        </div>
        <div class="co-info">
        <p class="download">20,000+</p>
        <p class="download-txt">Authorised Persons</p>
        </div>
        <div class="co-info">
        <p class="download">1 Cr+</p>
        <p class="download-txt">Daily Visitors</p>
        </div>
        </div>
        </div>
        </section> */}
          <div className="container text-center">
            <h1 className="text-black" style={{ fontWeight: 900, paddingTop: 100 }}>
              {" "}
              Find us on Social Media
            </h1>
            <div className="social-icons">
              <Link to="#">
                <img src="img/mail.png" />
              </Link>
              <Link to="#">
                <img src="img/linkedin.png" />
              </Link>
              <Link to="#">
                <img src="img/twitter.png" />
              </Link>
              <Link to="#">
                <img src="img/whatsapp.png" />
              </Link>
              <Link to="#">
                <img src="img/facebook.png" />
              </Link>
            </div>
          </div>
        </section>
        {/* Button trigger modal */}
        <div type='button'
          className="whatsapp_float"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <span className="unread-message-count">1</span>
          <img src="./img/mail.png" alt="" />
        </div>
        {/* Modal */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="infographic" style={{textAlign:"justify"}}>
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Important Update
                  </h1>
                  <div
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div
                  className="modal-body"
                >
                  <div
                    className="full-content"
                  >
                    Risk disclosures on derivatives
                    <br />
                    Risk disclosure in the Equity Futures &amp; Options segment aims
                    to inform individual traders about the inherent risks involved in
                    trading these derivative instruments. By disclosing these risks,
                    regulatory bodies such as SEBI seek to ensure that traders are
                    fully aware of the nature of these instruments and the challenges
                    they pose.
                  </div>
                    <li style={{paddingLeft:"10px"}}>
                      9 out of 10 individual traders in equity Futures and Options
                      Segment incurred net losses.
                    </li>
                  <div
                    className="full-content"
                    style={{marginTop:"0px",
                  paddingTop:"0px"}}
                  >
                    <li>
                      On an average, loss makers registered net trading loss close to
                      ₹ 50,000.
                    </li>
                    <li>
                      Over and above the net trading losses incurred, loss makers
                      expended an additional 28% of net trading losses as transaction
                      costs.
                    </li>
                    <li>
                      Those making net trading profits incurred between 15% to 50% of
                      such profits as transaction cost.
                    </li>
                    <Link
                      to="https://www.sebi.gov.in/sebi_data/attachdocs/may-2023/1684493716856.pdf"
                      target="_blank" rel="noreferrer"
                    >
                      SEBI CIRCULAR <br />
                      (SEBI/HO/MIRSD/MIRSD-PoD-1/P/CIR/2023/73)
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
       );
}

export default Front;