import React from 'react'
function Faq() {
    return ( <>
        <section id="home" style={{ padding: 0 }}>
          {/* <div id="header"></div> */}
          <div style={{ marginTop: "8.7%" }} />
          <section className="container mt-3">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li
                className="nav-item"
                data-toggle="tooltip"
                title="Equity"
                data-placement="top"
                role="presentation"
              >
                <a
                  className="nav-link active"
                  id="equity-tab"
                  data-bs-toggle="tab"
                  href="#equity-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="equity-tab-pane"
                  aria-selected="true"
                >
                  <h4>Equity</h4>
                </a>
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                title="Derivative"
                data-placement="top"
                role="presentation"
              >
                <a
                  className="nav-link "
                  id="derivative-tab"
                  data-bs-toggle="tab"
                  href="#derivative-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="derivative-tab-pane"
                  aria-selected="false"
                >
                  <h4>Derivative</h4>
                </a>
              </li>
              <li
                className="nav-item"
                data-toggle="tooltip"
                title="Margin Pledge and Re-pledge"
                data-placement="top"
                role="presentation"
              >
                <a
                  className="nav-link "
                  id="others-tab"
                  data-bs-toggle="tab"
                  href="#others"
                  type="button"
                  role="tab"
                  aria-controls="others-tab-pane"
                  aria-selected="false"
                >
                  <h4>Margin Pledge and Re-pledge</h4>
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active my-2"
                id="equity-tab-pane"
                role="tabpanel"
                aria-labelledby="equity-tab"
                tabIndex={0}
              >
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        1. What is an Equity Share?
                      </div>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Equity shares signify a form of proprietorship. Individuals
                        who hold these shares are considered members or shareholders
                        of the company and are entitled to exercise voting rights
                        during decision-making events.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2. What returns can I expect from my investments in equity
                        shares? What are the risks?
                      </div>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Investing in equity shares can be likened to a "high-stakes,
                        high-reward" venture. Unlike other financial instruments like
                        bank savings, government-backed savings plans, debentures, or
                        bonds, which typically offer predetermined returns, the
                        potential gains from equities are unpredictable and can
                        fluctuate significantly. Selecting a promising stock at the
                        opportune moment can yield substantial profits; however, if
                        not chosen wisely, the returns could be minimal, or there's
                        even a possibility of facing losses, diminishing the principal
                        amount. In essence, while investments in fixed-income assets
                        are largely stable and offer a safety net, diving into the
                        equity market involves embracing a higher level of risk.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3. What is a Bonus Share?
                      </div>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        A Share issued by companies to their shareholders free of cost
                        by capitalization of accumulated reserves from the profits
                        earned in the earlier years.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4. What is Dividend?
                      </div>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Dividend is the part of profit distributed by the company
                        among its investors. It is usually declared as a percentage of
                        the paid-up value or face value of the share.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        5. What is a Debenture?
                      </div>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        It is a Bond issued by a company bearing a fixed rate of
                        interest usually payable half-yearly on specific dates and
                        principal amount repayable on a particular date on redemption
                        of the debentures. Debentures are normally secured/charged
                        against the asset of the company in favor of debenture holder.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        6. What is a Bond?
                      </div>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        A Bond is a promissory note issued by a company or government
                        to its lenders. A Bond is evidence of debt on which the
                        issuing company usually promises to pay the bondholder a
                        specified amount of interest at intervals over a specified
                        length of time, and to repay the original loan on the
                        expiration date. A bond investor lends money to the issuer and
                        in exchange, the issuer promises to repay the loan amount on a
                        specified maturity date.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        7. What shares can I buy?
                      </div>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        You can buy the shares that are listed on any of the
                        recognized Stock Exchanges.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        8. Whom should I contact for my Stock Market related
                        transactions?
                      </div>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        To be able to buy or sell shares in the stock markets a client
                        would need to be registered with a stock broker like
                        OurBroking who holds membership in stock exchanges and who is
                        registered with SEBI.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        9. What is a Stock Exchange ?
                      </div>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        A Stock Exchange is a place where the buyer and seller meet to
                        trade in shares in an organized manner. There are at present
                        25 recognized stock exchanges in the country that are governed
                        by the Securities Contact (Regulation) Act, 1956.
                      </div>
                    </div>
                  </div>
                  {/* 10 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        10. How do I place my orders?
                      </div>
                    </h2>
                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Trading can be done via the email or by coming in person to
                        the backoffice of OurBroking. The dealer (employee of
                        OurBroking who is supposed to input the investors order into
                        the stock exchange order system) after checking the
                        authenticity of the person email and after checking the margin
                        available in the account would put/enter the order into the
                        stock exchange system.
                      </div>
                    </div>
                  </div>
                  {/* 11 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEleven"
                        aria-expanded="false"
                        aria-controls="collapseEleven"
                      >
                        11. Am I required to sign any agreement with the broker or
                        sub-broker?
                      </div>
                    </h2>
                    <div
                      id="collapseEleven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEleven"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Yes, you have to sign the “Member-Client agreement” for the
                        purpose of engaging a broker to execute trades on your behalf
                        from time to time and furnish details relating to yourself to
                        enable the member to maintain Client Registration Form.
                      </div>
                    </div>
                  </div>
                  {/* 12 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwelve">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwelve"
                        aria-expanded="false"
                        aria-controls="collapseTwelve"
                      >
                        12. What is Buying and Selling?
                      </div>
                    </h2>
                    <div
                      id="collapseTwelve"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwelve"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        There are several types of orders that you can dictate to a
                        broker. The most common type, which is a regular buy or sell
                        order, is called a market order. Another type of order is a
                        limit order wherein you ask the broker to trade only if the
                        price reaches a specific level. In a stop order, you tell the
                        broker to sell your shares if the price drops to a certain
                        level to prevent significant loss because if it drops to that
                        level it is likely to drop further and your losses are likely
                        to increase.
                      </div>
                    </div>
                  </div>
                  {/* 13 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirteen">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThirteen"
                        aria-expanded="false"
                        aria-controls="collapseThirteen"
                      >
                        13. What is a Member–Client Agreement form?
                      </div>
                    </h2>
                    <div
                      id="collapseThirteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThirteen"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        This form is an agreement entered into between client and
                        broker in the presence of witnesses wherein the client agrees
                        (is desirous) to trade/invest in the securities listed on the
                        concerned Exchange through the broker after being satisfied of
                        broker’s capabilities to deal in the same.
                      </div>
                    </div>
                  </div>
                  {/* 14 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFourteen">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFourteen"
                        aria-expanded="false"
                        aria-controls="collapseFourteen"
                      >
                        14. What is taking a position?
                      </div>
                    </h2>
                    <div
                      id="collapseFourteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFourteen"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        When you act upon a stock and buy into it, you are taking a
                        position. A position is an amount of money committed to an
                        investment in anticipation of favorable price movements.There
                        are two kinds of positions : - Long positions are what most
                        people do. When you buy long, that means you are anticipating
                        an upward movement in the price, and that is how you profit.
                        People usually buy stocks at prices expecting to sell them
                        later at higher prices and hence make profits. Short positions
                        are the tricky ones. When you buy short, you are anticipating
                        a fall in the price and the fall is the source of your
                        profits. The shares will be sold and when the price falls they
                        will be repurchased and given back and the difference is the
                        where the investor profits. Of course, the investor who
                        borrowed the shares carries the risk of not having the price
                        move as anticipated, in which case he may lose money in
                        repurchasing the stocks.
                      </div>
                    </div>
                  </div>
                  {/* 15 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFifteen">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFifteen"
                        aria-expanded="false"
                        aria-controls="collapseFifteen"
                      >
                        15. How do I make or receive payments to or from OurBroking?
                      </div>
                    </h2>
                    <div
                      id="collapseFifteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFifteen"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Payments to OurBroking has to be made via a Account Payee
                        cheque/Demand Draft in favor of OurBroking. The payment should
                        necessarily come from the bank account of the investor and not
                        from any other person. Similarly OurBroking would pay an
                        Account Payee cheque in the name of the investor, which will
                        also contain the Bank name and account number of the client.
                      </div>
                    </div>
                  </div>
                  {/* 16 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSixteen">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSixteen"
                        aria-expanded="false"
                        aria-controls="collapseSixteen"
                      >
                        16. What is meant by bullish and bearish trend?
                      </div>
                    </h2>
                    <div
                      id="collapseSixteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSixteen"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        When the market goes up it is called a bullish trend and when
                        the market goes down it is called a bearish trend.
                      </div>
                    </div>
                  </div>
                  {/* 17 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeventeen">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeventeen"
                        aria-expanded="false"
                        aria-controls="collapseSeventeen"
                      >
                        17. What is a Rolling Settlement?
                      </div>
                    </h2>
                    <div
                      id="collapseSeventeen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeventeen"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        In a Rolling Settlement trades executed during the day are
                        settled based on the net obligations for the day. In NSE and
                        BSE, the trades pertaining to the rolling settlement are
                        settled on a T+2 day basis where T stands for the trade day.
                        Hence trades executed on a Monday are typically settled on the
                        following Wednesday (considering 2 working days from the trade
                        day). The funds and securities pay-in and pay-out are carried
                        out on T+2 day.
                      </div>
                    </div>
                  </div>
                  {/* 18 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEighteen">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEighteen"
                        aria-expanded="false"
                        aria-controls="collapseEighteen"
                      >
                        18. What is a Contract Note?
                      </div>
                    </h2>
                    <div
                      id="collapseEighteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEighteen"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        "A Contract Note serves as a record of transactions conducted
                        on a specified day on behalf of an investor. This note not
                        only provides a detailed account of the trades but also
                        legally binds the client and the brokerage firm, ensuring the
                        completion of the trades. Details displayed on the Contract
                        Note typically include the settlement number, order and trade
                        numbers, the exact time of the transaction, the number of
                        shares, their price, and the brokerage fee. The note is
                        authenticated with the signature of an approved representative
                        from the brokerage firm."
                      </div>
                    </div>
                  </div>
                  {/* 19 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNineteen">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNineteen"
                        aria-expanded="false"
                        aria-controls="collapseNineteen"
                      >
                        19. What is a depository?
                      </div>
                    </h2>
                    <div
                      id="collapseNineteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNineteen"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Think of a depository as analogous to a bank, but instead of
                        money, it holds an investor's securities such as shares,
                        bonds, government securities, and other financial instruments
                        in a digital format. Beyond just holding these securities, a
                        depository facilitates various transactions related to them.
                        In the Indian context, there are primarily two major
                        depositories: the National Securities Depository Ltd. (NSDL)
                        and the Central Depository Securities Ltd. (CDSL). Both are
                        overseen by the Securities and Exchange Board of India (SEBI).
                        OurBroking functions as a Depository Participant with NSDL,
                        ensuring your securities are securely held in an electronic
                        form.{" "}
                      </div>
                    </div>
                  </div>
                  {/* 20 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwenty">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwenty"
                        aria-expanded="false"
                        aria-controls="collapseTwenty"
                      >
                        20. What is pay-in day and pay-out day?
                      </div>
                    </h2>
                    <div
                      id="collapseTwenty"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwenty"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Pay-in day is the day when the broker shall make payment or
                        delivery of securities to the exchange. Pay-out day is the day
                        when the exchange makes payment or delivery of securities to
                        the broker.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyOne">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwentyOne"
                        aria-expanded="false"
                        aria-controls="collapseTwentyOne"
                      >
                        21. What should I do when I want to open an account with a DP?
                      </div>
                    </h2>
                    <div
                      id="collapseTwentyOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwentyOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        You can approach OurBroking or any DP of your choice and fill
                        up an account opening form. At the time of opening an account,
                        you have to sign an agreement with DP in a NSDL prescribed
                        standard agreement, which details your and your DP’s rights
                        and duties.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyTwo">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwentyTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwentyTwo"
                      >
                        22. What do you mean by ‘Market Trades’ and ‘Off Market
                        Trades’?
                      </div>
                    </h2>
                    <div
                      id="collapseTwentyTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwentyTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Any trade settled through a clearing corporation is termed as
                        a ‘Market Trade’. These trades are done through stock brokers
                        on a stock exchange. ‘Off Market Trade’ is one which is
                        settled directly between two parties without the involvement
                        of a clearing corporation. The same delivery instruction slip
                        can be used either for market trade or off-market trade by
                        ticking one of the two options.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyThree">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwentyThree"
                        aria-expanded="false"
                        aria-controls="collapseTwentyThree"
                      >
                        23. What is an Auction?
                      </div>
                    </h2>
                    <div
                      id="collapseTwentyThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwentyThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Stock exchanges implement an auction system when a selling
                        member doesn't provide the securities they've sold, ensuring
                        the buying member receives the expected securities. This
                        failure to deliver might result from a short delivery
                        scenario. In such cases, the Exchange procures the missing
                        amount from the Auction Market, transferring them to the
                        buying member to complete the trade.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyFour">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwentyFour"
                        aria-expanded="false"
                        aria-controls="collapseTwentyFour"
                      >
                        24. What happens if I could not make the payment of money or
                        deliver shares on the pay-in day?
                      </div>
                    </h2>
                    <div
                      id="collapseTwentyFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwentyFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        When you make a purchase through a broker, they have the
                        discretion to liquidate the securities if you do not complete
                        the full payment before the designated pay-in day set by the
                        Stock Exchange for that specific settlement period, provided
                        there's no equivalent credit with them on your behalf. For
                        sales shortages, they are addressed via an auction system. The
                        price difference between the value stated in the Contract Note
                        and the auctioned price is settled by the broker with the
                        Exchange. This amount then becomes due from the client. In any
                        scenario where there's a loss, it can be deduced from the
                        initial margin you've deposited.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyFive">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwentyFive"
                        aria-expanded="false"
                        aria-controls="collapseTwentyFive"
                      >
                        25. What happens if the shares are not bought in the auction?
                      </div>
                    </h2>
                    <div
                      id="collapseTwentyFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwentyFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        If a particular set of shares couldn't be acquired during the
                        auction, meaning no shares were presented for sale, the
                        transaction is settled following SEBI's close-out guidelines.
                        These guidelines dictate that the close-out price is either
                        the highest price registered for that specific scrip on the
                        exchange from the moment the original contract was entered
                        into until the auction or close-out date, or it's 20% more
                        than the official closing price on the exchange on the day the
                        auction offers were initiated, whichever value is greater.
                        Given that auctions and close-outs occur during trading hours
                        in a rolling settlement, the reference price for close-out
                        procedures in such a settlement would be the closing price of
                        the previous day.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentySix">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwentySix"
                        aria-expanded="false"
                        aria-controls="collapseTwentySix"
                      >
                        26. What are the rights of the investor?
                      </div>
                    </h2>
                    <div
                      id="collapseTwentySix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwentySix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        The right to get - Proof of price/brokerage charged,
                        Money/shares on time, Statement of Accounts and Contract Note
                        from trading member.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentySeven">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwentySeven"
                        aria-expanded="false"
                        aria-controls="collapseTwentySeven"
                      >
                        27. How are margins paid?
                      </div>
                    </h2>
                    <div
                      id="collapseTwentySeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwentySeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Exchange prescribes margin rules from time to time, which
                        currently are calculated on the Value at Risk model. Margins
                        are to be paid by the investor before placing the order.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyEight">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwentyEight"
                        aria-expanded="false"
                        aria-controls="collapseTwentyEight"
                      >
                        28. What are the additional charges other than brokerage that
                        can be levied on the investor?
                      </div>
                    </h2>
                    <div
                      id="collapseTwentyEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwentyEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        The trading member can charge: Securities Transaction Tax.
                        Service tax as applicable. Transaction charges levied by NSE,
                        Stamp duty and other charges directly attributable to the
                        transaction. Note : The brokerage and service tax is indicated
                        separately in the contract note.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwentyNine">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwentyNine"
                        aria-expanded="false"
                        aria-controls="collapseTwentyNine"
                      >
                        29. How are margins paid?
                      </div>
                    </h2>
                    <div
                      id="collapseTwentyNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwentyNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Exchange prescribes margin rules from time to time, which
                        currently are calculated on the Value at Risk model. Margins
                        are to be paid by the investor before placing the order.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirty">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThirty"
                        aria-expanded="false"
                        aria-controls="collapseThirty"
                      >
                        30. What are the additional charges other than brokerage that
                        can be levied on the investor?
                      </div>
                    </h2>
                    <div
                      id="collapseThirty"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThirty"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        The trading member can charge: Securities Transaction Tax.
                        Service tax as applicable. Transaction charges levied by NSE,
                        Stamp duty and other charges directly attributable to the
                        transaction. Note : The brokerage and service tax is indicated
                        separately in the contract note.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirtyOne">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThirtyOne"
                        aria-expanded="false"
                        aria-controls="collapseThirtyOne"
                      >
                        31. What happens if I do not get my money or share on the due
                        date?
                      </div>
                    </h2>
                    <div
                      id="collapseThirtyOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThirtyOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        In case a broker fails to deliver to you in time and make the
                        proper payment of money/shares or you have a complaint against
                        the conduct of the broker, you can file a complaint with the
                        respective stock exchange. The exchange is required to resolve
                        all complaints. To resolve the dispute the complainant can
                        also resort to arbitration as provided on the reverse of
                        Contract Note /Purchase or Sale Note. However, if the
                        complaint is not addressed by the Stock Exchanges or is unduly
                        delayed then the complaints along with supporting documents
                        may be forwarded to Secondary Market Department of SEBI. Your
                        complaint would be followed up with the exchanges for
                        expeditious redressal. In case of a complaint against a
                        sub-broker, for redressal the complaint may be forwarded to
                        the concerned broker with whom the subbroker is affiliated.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirtyTwo">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThirtyTwo"
                        aria-expanded="false"
                        aria-controls="collapseThirtyTwo"
                      >
                        32. What are the obligations of the investor?
                      </div>
                    </h2>
                    <div
                      id="collapseThirtyTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThirtyTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        The obligation to - Sign a proper Member-Constituent Agreement
                        Possess a valid contract or purchase/sale note Deliver
                        securities &amp; make payment on time Provide Margin before
                        trade
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirtyThree">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThirtyThree"
                        aria-expanded="false"
                        aria-controls="collapseThirtyThree"
                      >
                        33. What are the various kinds of accounts that I need to
                        trade via Internet with OurBroking?
                      </div>
                    </h2>
                    <div
                      id="collapseThirtyThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThirtyThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Three kinds of accounts are required to be able to trade
                        on-line. They are: E-Broking account with OurBroking
                        Depository Participant (DP) account with OurBroking Bank
                        account which has developed an interface with “OurBroking”
                        i.e. designated banks like HDFC Bank, UTI Bank, Citi Bank.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirtyFour">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThirtyFour"
                        aria-expanded="false"
                        aria-controls="collapseThirtyFour"
                      >
                        34. What are the tax implications of investing in Indian
                        equities?
                      </div>
                    </h2>
                    <div
                      id="collapseThirtyFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThirtyFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Tax rates on investments gains are categorized as long term
                        &amp; short term capital gains. Long term capital gains Long
                        Term investments that are held for more than 12 months are
                        termed as long term capital assets. Profit on sale of such
                        assets is termed as long term capital gain (LTCG) which as per
                        the latest Budget notification will attract nil tax. Short
                        term capital gains Shares that are held for less than 12
                        months are classified as short term capital assets which as
                        per the latest Budget notification will attract 15% tax.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade my-2"
                id="derivative-tab-pane"
                role="tabpanel"
                aria-labelledby="derivative-tab"
                tabIndex={0}
              >
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingone">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseone"
                        aria-expanded="false"
                        aria-controls="collapseone"
                      >
                        1. What are derivatives?
                      </div>
                    </h2>
                    <div
                      id="collapseone"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingone"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Derivatives represent financial agreements whose value is
                        anchored to an underlying asset's spot price. These underlying
                        assets can range from equity shares, commodities, gold,
                        treasury bills, foreign exchange, to even real estate.
                        Commonly referred to as 'contracts,' these financial
                        instruments like futures and options serve as critical
                        components in global financial markets. While the intrinsic
                        value of derivatives is based on these underlying assets,
                        their market significance goes beyond just that. They play a
                        pivotal role in risk management, portfolio optimization, and
                        even speculative trading activities. Across the globe, these
                        financial contracts aid in maintaining market stability,
                        enhancing liquidity, and ensuring smoother price transitions.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingtwo">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsetwo"
                        aria-expanded="false"
                        aria-controls="collapsetwo"
                      >
                        2. What are the problems of forward markets?
                      </div>
                    </h2>
                    <div
                      id="collapsetwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingtwo"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Forward markets worldwide are afflicted by several problems:
                        (a) Lack of centralisation of trading, (b) Illiquidity, and
                        (c) Counterparty risk.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingthree">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsethree"
                        aria-expanded="false"
                        aria-controls="collapsethree"
                      >
                        3. Why forward contract is useful?
                      </div>
                    </h2>
                    <div
                      id="collapsethree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingthree"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Forward contracting is very valuable in hedging and
                        speculation. If a speculator has information or analysis which
                        forecasts an upturn in a price, then he can go long on the
                        forward market instead of the cash market. The speculator
                        would go long on the forward, wait for the price to rise, and
                        then take a reversing transaction making a profit.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingfour">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsefour"
                        aria-expanded="false"
                        aria-controls="collapsefour"
                      >
                        4. What Is A Forward Contract?
                      </div>
                    </h2>
                    <div
                      id="collapsefour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingfour"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        In a forward contract, two parties agree to do a trade at some
                        future date, at a stated price and quantity. No money changes
                        hands at the time the deal is signed.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingfive">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsefive"
                        aria-expanded="false"
                        aria-controls="collapsefive"
                      >
                        5. What are various products available for trading in Futures
                        and Options?
                      </div>
                    </h2>
                    <div
                      id="collapsefive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingfive"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Futures and options contracts are traded on Indices and on
                        Single stocks.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingsix">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsesix"
                        aria-expanded="false"
                        aria-controls="collapsesix"
                      >
                        6. What are various types of derivative instruments traded at
                        Exchanges?
                      </div>
                    </h2>
                    <div
                      id="collapsesix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingsix"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Derivatives traded on exchanges come in two primary forms:
                        Futures and Options. A 'Futures' contract signifies an
                        agreement to buy or sell a specific asset at a predetermined
                        price on a future date. On the other hand, 'Options' provide
                        the holder the choice, but not the obligation, to buy or sell
                        at a set price on or before a specific date. Within options,
                        there are 'Calls', which offer the right to buy, and 'Puts',
                        granting the right to sell. The seller of an option earns the
                        premium and must fulfill the contract if the buyer chooses to
                        exercise it. Options can be cash-settled, and their exercise
                        styles can be either American (exercisable at any time until
                        expiration) or European (exercisable only at expiration).
                        While individual security options at NSE follow the American
                        style, all index options use the European style.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingseven">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseseven"
                        aria-expanded="false"
                        aria-controls="collapseseven"
                      >
                        7. What are the benefits of trading in Index Futures compared
                        to any other security?
                      </div>
                    </h2>
                    <div
                      id="collapseseven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingseven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        An investor can trade the ‘entire stock market’ by buying
                        index futures instead of buying individual securities with the
                        efficiency of a mutual fund. The advantages of trading in
                        Index Futures are: • The contracts are highly liquid • Index
                        Futures provide higher leverage than any other stocks • It
                        requires low initial capital requirement • It has lower risk
                        than buying and holding stocks • It is just as easy to trade
                        the short side as the long side • Only have to study one index
                        instead of 100s of stocks
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingeight">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseeight"
                        aria-expanded="false"
                        aria-controls="collapseeight"
                      >
                        8. Why Should I trade in derivatives?
                      </div>
                    </h2>
                    <div
                      id="collapseeight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingeight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Futures trading will be of interest to those who wish to: 1)
                        Invest - take a view on the market and buy or sell
                        accordingly. 2) Price Risk Transfer- Hedging - Hedging is
                        buying and selling futures contracts to offset the risks of
                        changing underlying market prices. Thus it helps in reducing
                        the risk associated with exposures in underlying market by
                        taking a counter- positions in the futures market 3) Leverage-
                        Since the investor is required to pay a small fraction of the
                        value of the total contract as margins, trading in Futures is
                        a leveraged activity since the investor is able to control the
                        total value of the contract with a relatively small amount of
                        margin. Thus the Leverage enables the traders to make a larger
                        profit (or loss) with a comparatively small amount of
                        capital.Options trading will be of interest to those who wish
                        to : 1) Participate in the market without trading or holding a
                        large quantity of stock. 2) Protect their portfolio by paying
                        small premium amount. Benefits of trading in Futures and
                        Options : 1) Able to transfer the risk to the person who is
                        willing to accept them 2) Incentive to make profi ts with
                        minimal amount of risk capital 3) Lower transaction costs 4)
                        Provides liquidity, enables price discovery in underlying
                        market 5) Derivatives market are lead economic indicators.
                      </div>
                    </div>
                  </div>
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingnine">
                        <div
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsenine"
                          aria-expanded="false"
                          aria-controls="collapsenine"
                        >
                          9. Importance of Derivatives
                        </div>
                      </h2>
                      <div
                        id="collapsenine"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingnine"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" style={{ padding: 20 }}>
                          Derivatives are very important financial instruments for
                          risk management as they allow risks to be separated and more
                          precisely controlled. Derivatives are used to shift elements
                          of risk and therefore can act as a form of Opening Purchase
                          Transaction An opening purchase transaction is one that
                          creates or increases a long position in a given option
                          series. Opening Sale Transaction An opening sale transaction
                          is one that creates or increases a short position in a given
                          option series. Such a sale is also referred to as "writing"
                          an option contract. Closing Purchase Transaction A closing
                          purchase transaction is one that eliminates or reduces a
                          short position in a given option series. Such a purchase is
                          commonly referred to as "covering" a short option position.
                          Closing Sale Transaction A closing sale transaction is one
                          that eliminates or decreases a long position in a given
                          option series. What is open interest? Open interest refers
                          to the number of outstanding contracts in the exchange
                          market. Futures &amp; Options Contracts on Global Indices:
                          NSE had introduced Futures &amp; Options contracts on global
                          indices with effect from August 29,2011 with the
                          introduction of S&amp;P 500 indices &amp; DOW JONES
                          Industrial Average (DJIA) indices. Three serial monthly
                          contracts and following three quarterly expiry contracts in
                          MAR-JUN-SEP-DEC cycle are available for trading in Global
                          Indices. On May 03,2012 Futures &amp; Options contracts of
                          FTSE 100 introduced. Expiry day of Global indices is thhird
                          Friday of the expiry month. In case the third Friday of the
                          expiry month is a holiday, the contract shall expire on the
                          preceding business day of the expiry month. The final
                          Settlement price shall be based on the rates in respective
                          markets.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingten">
                        <div
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseten"
                          aria-expanded="false"
                          aria-controls="collapseten"
                        >
                          10. What are the Risks associated with trading in
                          Derivatives?
                        </div>
                      </h2>
                      <div
                        id="collapseten"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingten"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" style={{ padding: 20 }}>
                          Investors must understand that investment in derivatives has
                          an element of risk and is generally not an appropriate
                          avenue for someone of limited resources/ limited investment
                          and / or trading experience and low risk tolerance. An
                          investor should therefore carefully consider whether such
                          trading is suitable for him or her in the light of his or
                          her financial condition. An investor must accept that there
                          can be no guarantee of profits or no exception from losses
                          while executing orders for purchase and / or sale of
                          derivative contracts.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingeleven">
                        <div
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseeleven"
                          aria-expanded="false"
                          aria-controls="collapseeleven"
                        >
                          11. Is there any Margin payable?
                        </div>
                      </h2>
                      <div
                        id="collapseeleven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingeleven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" style={{ padding: 20 }}>
                          Yes. Margins are computed and collected on-line, real time
                          on a portfolio basis at the client level. Members are
                          required to collect the margin upfront from the client &amp;
                          report the same to the Exchange.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingtwelve">
                        <div
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsetwelve"
                          aria-expanded="false"
                          aria-controls="collapsetwelve"
                        >
                          12. What is the concept of In the money, At the money and
                          Out of the money in respect of Options?
                        </div>
                      </h2>
                      <div
                        id="collapsetwelve"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingtwelve"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" style={{ padding: 20 }}>
                          Options are categorized based on their relationship to the
                          current market price of the underlying asset. An
                          "In-the-money" option has value immediately upon exercise.
                          Specifically, a Call option is in-the-money when the market
                          price surpasses the strike price, and a Put option is
                          in-the-money when the market price is below the strike
                          price. Conversely, "Out-of-the-money" options wouldn't yield
                          profit if exercised. For a Call, it's when the market price
                          is beneath the strike price, and for a Put, it's when the
                          market price exceeds the strike price. Meanwhile,
                          "At-the-money" options have their strike price equal to the
                          market price. This means there's no immediate gain or loss
                          upon exercise. These classifications help investors gauge
                          the immediate value and potential risk of an option.
                        </div>
                      </div>
                    </div>
                    {/* najcskdnjlcndjoncdsj */}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade my-2"
                id="others"
                role="tabpanel"
                aria-labelledby="others-tab"
                tabIndex={0}
              >
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="Headingone">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Collapseone"
                        aria-expanded="false"
                        aria-controls="Collapseone"
                      >
                        1. What was the old system of margin?
                      </div>
                    </h2>
                    <div
                      id="Collapseone"
                      className="accordion-collapse collapse"
                      aria-labelledby="Headingone"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        A client who had PoA was given exposure against his existing
                        DP holdings. The client would keep his shares in his own DP,
                        and we in turn would give buying power. If required, at the
                        End of day risk management would transfer shares to pool
                        account or sell the position based on margin requirement using
                        PoA.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="Headingtwo">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Collapsetwo"
                        aria-expanded="false"
                        aria-controls="Collapsetwo"
                      >
                        2. How can a client request for pledge/ un-pledge?
                      </div>
                    </h2>
                    <div
                      id="Collapsetwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="Headingtwo"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Client can request for pledge/ unpledged in the following
                        ways: a) BackOffice b) Customer care email
                        (wecare@ourbroking.com) c) Physical form Please note for
                        selling you don’t require to unpledged, you can sell the
                        shares and OurBroking will release the pledge and give Pay-in
                        to the exchanges.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="Headingthree">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Collapsethree"
                        aria-expanded="false"
                        aria-controls="Collapsethree"
                      >
                        3. What is the new system of margin?
                      </div>
                    </h2>
                    <div
                      id="Collapsethree"
                      className="accordion-collapse collapse"
                      aria-labelledby="Headingthree"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        In the updated system, approved securities in the client's
                        portfolio can be marked as lien (pledged) in favor of
                        OurBroking. This action then allows the client to obtain
                        buying power based on the current market value, after applying
                        the necessary deductions. By marking the securities as a lien,
                        the client simultaneously agrees to allow OurBroking to
                        re-pledge these to the clearing corporation to secure
                        additional margins. One major advantage of this method is that
                        the shares remain in the customer’s Beneficiary Account.
                        Consequently, any benefits from corporate actions get directly
                        credited to the client's DP account or bank account.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="Headingfour">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Collapsefour"
                        aria-expanded="false"
                        aria-controls="Collapsefour"
                      >
                        4. How can a client pledge his/her shares for margin?
                      </div>
                    </h2>
                    <div
                      id="Collapsefour"
                      className="accordion-collapse collapse"
                      aria-labelledby="Headingfour"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        There are 2 steps involved in pledging securities to
                        OurBroking: a) Client gives Request(Scrip, quantity to be
                        pledged) b) Client gives Confirmation (Input PAN, get the OTP,
                        Confirm with OTP) Once DP gets confirmation message from
                        respective depositories, pledge will be marked.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="Headingfive">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Collapsefive"
                        aria-expanded="false"
                        aria-controls="Collapsefive"
                      >
                        5. What is the Physical Process and when should it be used?
                      </div>
                    </h2>
                    <div
                      id="Collapsefive"
                      className="accordion-collapse collapse"
                      aria-labelledby="Headingfive"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        The physical process should be used in the following cases: a)
                        If the client does not have email and mobile (irrespective of
                        whether he has PoA or not) b) If the client does not have PoA
                        and he is unable to pledge/unpledge through Customer Care
                        email or through IDeAS/ EASIEST
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="Headingsix">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Collapsesix"
                        aria-expanded="false"
                        aria-controls="Collapsesix"
                      >
                        6. Can I pledge through branch or by customer care?
                      </div>
                    </h2>
                    <div
                      id="Collapsesix"
                      className="accordion-collapse collapse"
                      aria-labelledby="Headingsix"
                      data-bs-parent="#accordionExample"
                      style={{}}
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        If you do not have PoA, then you cannot pledge/ unpledge
                        through back office or by customer care. You can either use
                        back office, Customer Care email, IDeAS/Easiest or physical
                        form.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="Headingseven">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Collapseseven"
                        aria-expanded="false"
                        aria-controls="Collapseseven"
                      >
                        7. Can I use NSDL/CDSL mobile and web applications?
                      </div>
                    </h2>
                    <div
                      id="Collapseseven"
                      className="accordion-collapse collapse"
                      aria-labelledby="Headingseven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        Yes, margin pledge is available in these applications. If you
                        have already registered for IDeAS or EASIEST you can use the
                        facility to pledge.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="Headingeight">
                      <div
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Collapseeight"
                        aria-expanded="false"
                        aria-controls="Collapseeight"
                      >
                        8. How are unpledge instructions executed?
                      </div>
                    </h2>
                    <div
                      id="Collapseeight"
                      className="accordion-collapse collapse"
                      aria-labelledby="Headingeight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" style={{ padding: 20 }}>
                        • Once shares are pledged, customers can request to un-pledge
                        the shares through customer care email, backoffice or physical
                        forms. • Unpledge does not require OTP • Unpledge instructions
                        will be validated against buying power utilisation and on
                        validating with RMS, the instruction will be sent to
                        Depositories to unpledge • Unpledge can be done partially as
                        well • Confirmation time window is the same as that of the
                        Pledge Process
                      </div>
                    </div>
                  </div>
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="Headingnine">
                        <div
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#Collapsenine"
                          aria-expanded="false"
                          aria-controls="Collapsenine"
                        >
                          9. What is repledge?
                        </div>
                      </h2>
                      <div
                        id="Collapsenine"
                        className="accordion-collapse collapse"
                        aria-labelledby="Headingnine"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" style={{ padding: 20 }}>
                          Securities that are pledged to Clearing Member can be
                          repledged to Clearing Corporation if required.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="Headingten">
                        <div
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#Collapseten"
                          aria-expanded="false"
                          aria-controls="Collapseten"
                        >
                          10. With the introduction of Pledge Margin, what are the
                          changes in the Cash Segment?
                        </div>
                      </h2>
                      <div
                        id="Collapseten"
                        className="accordion-collapse collapse"
                        aria-labelledby="Headingten"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" style={{ padding: 20 }}>
                          100% ordervalue is required as margin either by ledger
                          balance or Equivalent Pledge value after hair cut. Selling
                          from demat holding with POA is also considered as margin for
                          subsequent trades of the client subject to Early Pay in
                          being made on the same day to Exchange . OurBroking will
                          pull the shares from DP on T day and give early pay-in to
                          exchanges.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="Headingeleven">
                        <div
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#Collapseeleven"
                          aria-expanded="false"
                          aria-controls="Collapseeleven"
                        >
                          11. With the introduction of Pledge Margin, what are the
                          changes in Equity &amp; Currency Derivatives?
                        </div>
                      </h2>
                      <div
                        id="Collapseeleven"
                        className="accordion-collapse collapse"
                        aria-labelledby="Headingeleven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" style={{ padding: 20 }}>
                          100 % SPAN + Exposure (Actual Exchange Margin) margin on
                          order value is required either by ledger balance or
                          Equivalent Pledge value after hair cut. Selling from demat
                          holding with POA is also considered as margin for subsequent
                          trades of the client subject to early pay-in being made on
                          the same day to Exchange
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="Headingtwelve">
                        <div
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#Collapsetwelve"
                          aria-expanded="false"
                          aria-controls="Collapsetwelve"
                        >
                          12. Can I sell the pledged shares?
                        </div>
                      </h2>
                      <div
                        id="Collapsetwelve"
                        className="accordion-collapse collapse"
                        aria-labelledby="Headingtwelve"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" style={{ padding: 20 }}>
                          Yes you will be permitted to sell the shares subject to
                          maintaining sufficient margins.
                        </div>
                      </div>
                    </div>
                    {/* najcskdnjlcndjoncdsj */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <div
          className="modal fade"
          id="Basic_Services_Demat_Account"
          tabIndex={-1}
          aria-labelledby="Basic_Services_Demat_Account_(BSDA)Title"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="Basic_Services_Demat_Account_(BSDA)Title"
                >
                  Basic Services Demat Account (BSDA)
                </h5>
                <div
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <li>
                        The facility of BSDA with limited services for eligible
                        individuals was introduced with the objective of achieving
                        wider financial inclusion and to encourage holding of demat
                        accounts. No Annual Maintenance Charges (AMC) shall be levied,
                        if the value of securities holding is upto Rs. 50,000. For
                        value of holdings between Rs 50,001- 2,00,000, AMC not
                        exceeding Rs 100 is chargeable. In case of debt securities,
                        there are no AMC charges for holding value upto Rs 1,00,000
                        and a maximum of Rs 100 as AMC is chargeable for value of
                        holdings between Rs 1,00,001 and Rs 2,00,000.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Transposition_cum_dematerialization"
          tabIndex={-1}
          aria-labelledby="Transposition_cum_dematerializationTitle"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="Transposition_cum_dematerializationTitle"
                >
                  Transposition cum dematerialization
                </h5>
                <div
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <li>
                        In case of transposition-cum-dematerialisation, client can get
                        securities dematerialised in the same account if the names
                        appearing on the certificates match with the names in which
                        the account has been opened but are in a different order. The
                        same may be done by submitting the security certificates along
                        with the Transposition Form and Demat Request Form.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Linkages_with_Clearing_System"
          tabIndex={-1}
          aria-labelledby="Linkages_with_Clearing_SystemTitle"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="Linkages_with_Clearing_SystemTitle">
                  Linkages with Clearing System
                </h5>
                <div
                  type="button"
                  style={{ color: "aqua" }}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <li>
                        Linkages with Clearing System for actual delivery of
                        securities to the clearing system from the selling brokers and
                        delivery of securities from the clearing system to the buying
                        broker.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="E-account_opening"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="E-account_openingTitle"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="E-account_openingTitle">
                  E-account opening
                </h5>
                <div
                  type="button"
                  style={{ color: "aqua" }}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <li>
                        Account opening through digital mode, popularly known as
                        “On-line Account opening”, wherein investor intending to open
                        the demat account can visit DP website, fill in the required
                        information, submit the required documents, conduct video IPV
                        and demat account gets opened without visiting DPs office.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Online_instructions_for_execution"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="Online_instructions_for_executionTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="Online_instructions_for_executionTitle"
                >
                  Online instructions for execution
                </h5>
                <div
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <li>
                        Online instructions for execution internet-enabled services
                        like Speed-e (NSDL) &amp; Easiest (CDSL) empower a demat
                        account holder in managing his/her securities
                        ‘anytime-anywhere’ in an efficient and convenient manner and
                        submit instructions online without the need to use paper.
                        These facilities allows Beneficial Owner (BO) to submit
                        transfer instructions and pledge instructions including margin
                        pledge from their demat account. The instruction facilities
                        are also available on mobile applications through android,
                        windows and IOS platforms.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="e-DIS_Demat_Gateway"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="e-DIS_Demat_GatewayTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="e-DIS_Demat_GatewayTitle">
                  e-DIS / Demat Gateway
                </h5>
                <div
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <li>
                        Investors can give instructions for transfer of securities
                        through e-DIS apart from physical DIS. Here, for on-market
                        transfer of securities, investors need to provide settlement
                        number along with the ISIN and quantity of securities being
                        authorized for transfer. Client shall be required to authorize
                        each e-DIS valid for a single settlement number / settlement
                        date, by way of OTP and PIN/password, both generated at
                        Depositories end. Necessary risk containment measures are
                        being adopted by Depositories in this regard.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="e-CAS_facility"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="e-CAS_facilityTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="e-CAS_facilityTitle">
                  e-CAS facility
                </h5>
                <div
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <li>
                        Consolidated Account Statements are available online and could
                        also be accessed through mobile app to facilitate the
                        investors to view their holdings in demat form.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Miscellaneous_services"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="Miscellaneous_servicesTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="Miscellaneous_servicesTitle">
                  Miscellaneous services
                </h5>
                <div
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <li>
                        Transaction alerts through SMS, e-locker facilities, chatbots
                        for instantaneously responding to investor queries etc. have
                        also been developed.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Investor_Grievance_Redressal_Committee_of_Depository"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="Investor_Grievance_Redressal_Committee_of_DepositoryTitle"
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="Investor_Grievance_Redressal_Committee_of_DepositoryTitle"
                >
                  Investor Grievance Redressal Committee of Depository
                </h5>
                <div
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <span>
                        If no amicable resolution is arrived, then the Investor has
                        the option to refer the complaint/ grievance to the Grievance
                        Redressal Committee (GRC) of the Depository. Upon receipt of
                        reference, the GRC will endeavor to resolve the complaint/
                        grievance by hearing the parties, and examining the necessary
                        information and documents
                      </span>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Arbitration_proceedings"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="Arbitration_proceedingsTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="Arbitration_proceedingsTitle">
                  Arbitration proceedings
                </h5>
                <div
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <span>
                        The Investor may also avail the arbitration mechanism set out
                        in the Byelaws and Business Rules/Operating Instructions of
                        the Depository in relation to any grievance, or dispute
                        relating to depository services. The arbitration reference
                        shall be concluded by way of issue of an arbitral award within
                        4 months from the date of appointment of arbitrator(s).
                      </span>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Dos_and_Don’ts_for_Investor"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="Dos_and_Don’ts_for_InvestorTitle"
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="Dos_and_Don’ts_for_InvestorTitle">
                  Dos and Don’ts for Investor
                </h5>
                <div
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <div className="table-2 table-responsive">
                      <table className="table table-bordered border-primary">
                        <tbody>
                          <tr className="head text-white">
                            <td style={{ fontSize: 16 }} scope="col">
                              <strong>S.No</strong>
                            </td>
                            <td style={{ fontSize: 16 }} scope="col">
                              <strong>Guidance</strong>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">1</th>
                            <td>
                              Always deal with a SEBI registered Depository
                              Participant for opening a demat account.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td>
                              Read all the documents carefully before signing them
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td>
                              Before granting Power of attorney to operate your demat
                              account to an intermediary like Stock Broker, Portfolio
                              Management Services (PMS) etc., carefully examine the
                              scope and implications of powers being granted.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">4</th>
                            <td>
                              Always make payments to registered intermediary using
                              banking channels. No payment should be made in name of
                              employee of intermediary.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">5</th>
                            <td>
                              Accept the Delivery Instruction Slip (DIS) book from
                              your DP only (pre-printed with a serial number along
                              with your Client ID) and keep it in safe custody and do
                              not sign or issue blank or partially filled DIS slips.
                              <br />
                              Always mention the details like ISIN, number of
                              securities accurately. In case of any queries, please
                              contact your DP or broker and it should be signed by all
                              demat account holders.
                              <br />
                              Strike out any blank space on the slip and Cancellations
                              or corrections on the DIS should be initialed or signed
                              by all the account holder(s).
                              <br />
                              Do not leave your instruction slip book with anyone
                              else.
                              <br />
                              Do not sign blank DIS as it is equivalent to a bearer
                              cheque.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">6</th>
                            <td>
                              Inform any change in your Personal Information (for
                              example address or Bank Account details, email ID,
                              Mobile number) linked to your demat account in the
                              prescribed format and obtain confirmation of updation in
                              system
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">7</th>
                            <td>
                              Mention your Mobile Number and email ID in account
                              opening form to receive SMS alerts and regular updates
                              directly from depository.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">8</th>
                            <td>
                              Always ensure that the mobile number and email ID linked
                              to your demat account are the same as provided at the
                              time of account opening/updation.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">9</th>
                            <td>
                              Do not share password of your online trading and demat
                              account with anyone.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">10</th>
                            <td>
                              Do not share One Time Password (OTP) received from
                              banks, brokers, etc. These are meant to be used by you
                              only.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">11</th>
                            <td>
                              Do not share login credentials of e-facilities provided
                              by the depositories such as e-DIS/demat gateway,
                              SPEED-e/easiest etc. with anyone else.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">12</th>
                            <td>
                              Demat is mandatory for any transfer of securities of
                              Listed public limited companies with few exceptions.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">13</th>
                            <td>
                              If you have any grievance in respect of your demat
                              account, please write to designated email IDs of
                              depositories or you may lodge the same with SEBI online
                              at{" "}
                              <a href="https://scores.gov.in/scores/Welcome.html">
                                https://scores.gov.in/scores/Welcome.html
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">14</th>
                            <td>
                              Keep a record of documents signed, DIS issued and
                              account statements received.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">15</th>
                            <td>
                              {" "}
                              As Investors you are required to verify the transaction
                              statement carefully for all debits and credits in your
                              account. In case of any unauthorized debit or credit,
                              inform the DP or your respective Depository.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">16</th>
                            <td>
                              Appoint a nominee to facilitate your heirs in obtaining
                              the securities in your demat account, on completion of
                              the necessary procedures.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">17</th>
                            <td>
                              Register for Depository's internet based facility or
                              download mobile app of the depository to monitor your
                              holdings.
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">18</th>
                            <td>
                              Ensure that, both, your holding and transaction
                              statements are received periodically as instructed to
                              your DP. You are entitled to receive a transaction
                              statement every month if you have any transactions.{" "}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">19</th>
                            <td>
                              Do not follow herd mentality for investments. Seek
                              expert and professional advice for your investments
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">20</th>
                            <td>Beware of assured/fixed returns.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Rights_of_investors"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="Rights_of_investorsTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="Arbitration_proceedingsTitle">
                  Rights of investors
                </h5>
                <div
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
              </div>
              <div className="modal-body">
                <div className="poup-holder">
                  <div className="row">
                    <ul className="list-div">
                      <li>
                        Receive a copy of KYC, copy of account opening documents.
                      </li>
                      <li>
                        No minimum balance is required to be maintained in a demat
                        account.
                      </li>
                      <li>No charges are payable for opening of demat accounts.</li>
                      <li>
                        If executed, receive a copy of Power of Attorney. However,
                        Power of Attorney is not a mandatory requirement as per SEBI /
                        Stock Exchanges. You have the right to revoke any
                        authorization given at any time.
                      </li>
                      <li>
                        You can open more than one demat account in the same name with
                        single DP/ multiple DPs.
                      </li>
                      <li>
                        Receive statement of accounts periodically. In case of any
                        discrepancies in statements, take up the same with the DP
                        immediately. If the DP does not respond, take up the matter
                        with the Depositories.
                      </li>
                      <li>
                        Pledge and /or any other interest or encumbrance can be
                        created on demat holdings.
                      </li>
                      <li>
                        Right to give standing instructions with regard to the
                        crediting of securities in demat account.
                      </li>
                      <li>
                        Investor can exercise its right to freeze/defreeze his/her
                        demat account or specific securities / specific quantity of
                        securities in the account, maintained with the DP.
                      </li>
                      <li>
                        In case of any grievances, Investor has right to approach
                        Participant or Depository or SEBI for getting the same
                        resolved within prescribed timelines.
                      </li>
                      <li>
                        Every eligible investor shareholder has a right to cast its
                        vote on various resolutions proposed by the companies for
                        which Depositories have developed an internet based ‘e-Voting’
                        platform.
                      </li>
                      <li>
                        Receive information about charges and fees. Any charges/tariff
                        agreed upon shall not increase unless a notice in writing of
                        not less than thirty days is given to the Investor.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
       );
}

export default Faq;