import React from 'react'
function Tnc() {
    return ( <section id="home" style={{ padding: "0!important" }}>
    <div style={{ marginTop: "8.7%" }} />
    <main className="content parent-content">
      <main className="content" style={{ textAlign: "justify" }}>
        <section className="static-sections static-header container border-bottom text-center section-head">
          <h1>Terms and conditions</h1>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>OUR BROKING</h3>
            <p className="text-grey">
              This page contains important information regarding the terms and
              conditions which apply to your trading and demat account with OUR
              BROKING Limited. OUR BROKING, being a Trading Member of NSE and BSE,
              registered with Securities &amp; Exchange Board of India ("SEBI")
              and having Registration No. INZ000031633 for
              Cash/Derivatives/Currency Derivatives segments of NSE &amp; BSE. The
              Depository services for your account will be enabled through OUR
              BROKING Limited, a Depository Participant using CDSL as the
              Depository having SEBI Registration no.: IN-DP-750-2023. Access to
              your account and the usage of your account is subject to your
              compliance with all the terms and conditions set forth herein, read
              along with all documents, including but not limited to; applications
              forms and undertakings, signed by you during account opening. Please
              read this page carefully and retain it for future reference.
            </p>
            <p className="text-grey">
              The website is owned, operated and maintained by OUR BROKING Limited
              (hereinafter referred to as “OUR BROKING”), a Company incorporated
              under the Companies Act, 2013 having CIN: U65929KA2018PLC116815, and
              having its Registered Office at Room no-561, LSE building, Firoze
              Gandhi Market, Ludhiana, Punjab-141001
            </p>
            <p className="text-grey">
              Please note that the information contained herein is subject to
              change without notice.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>General terms and conditions</h3>
            <p className="text-grey">
              Before availing of online trading services, the Client shall
              complete the registration process as may be prescribed from time to
              time. The Client shall follow the instruction given in the website
              for registering himself as a client.
            </p>
            <p className="text-grey">
              The Client agrees that all investment and disinvestment decisions
              are based on the Client's own evaluation of financial circumstances
              and investment objectives. This extends to any decisions made by the
              Client on the basis of any information that may be made available on
              the web site of OUR BROKING. The Client will not hold nor seek to
              hold OUR BROKING or any of its officers, directors, partners,
              employees, agents, subsidiaries, affiliates or business associates
              liable for any trading losses, cost of damage incurred by the Client
              consequent upon relying on investment information, research opinions
              or advice or any other material/information whatsoever on the web
              site, literature, brochure issued by OUR BROKING or any other agency
              appointed/authorised by OUR BROKING. The Client should seek
              independent professional advice regarding the suitability of any
              investment decisions. The Client also acknowledges that employees of
              OUR BROKING are not authorized to give any such advice and that the
              Client will not solicit or rely upon any such advice from OUR
              BROKING or any of its employees.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>Our Trademarks and Copyright</h3>
            <p className="text-grey">
              All content, logos, trademarks, and intellectual properties on our
              website are the exclusive property of OUR BROKING Services.
              Unauthorized use, without explicit consent, is strictly forbidden.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>Use of Information</h3>
            <p className="text-grey">
              ourbroking.com disclaims any duty to frequently update or ensure the
              pinpoint accuracy of information/content available on
              http://www.ourbroking.com. The content undergoes periodic
              refreshment and is reflective of data as of a specified date. It's
              the user's duty to understand that this information may be subject
              to changes based on market dynamics. Any updated terms concerning
              our services will be available on the site. It's imperative to
              ascertain regional legality before subscribing, as some services may
              be restricted.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>Online Trading System</h3>
            <p className="text-grey">
              Prospective clients interested in our online trading services need
              to undertake a registration procedure. It's pivotal for the client
              to ensure the authenticity of the information they provide. OUR
              BROKING holds no accountability for any misinformation from our
              platform or external sources concerning investments.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>No Warranties</h3>
            <p className="text-grey">
              While we exert maximum effort to ensure the accuracy of information
              on this site, it's presented "as is", devoid of any guarantees. This
              includes, but isn't limited to, guarantees about security, accuracy,
              or the absence of malicious entities.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>E-mail</h3>
            <p className="text-grey">
              Digital communications, including e-mails, can't guarantee absolute
              security. OUR BROKING Services isn’t liable for any adverse outcomes
              linked to this mode of communication.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>BackOffice</h3>
            <p className="text-grey">
              Clients might obtain access to our back-office tools and associated
              resources. Safeguarding these credentials is the sole responsibility
              of the client. Immediate communication is essential if there are any
              issues regarding these credentials.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>Transmitting over the Internet</h3>
            <p className="text-grey">
              Given the inherent unpredictability of the internet, disruptions, or
              errors in transmission can happen. We aren't liable for external
              issues affecting your digital communications with us.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>Downloading</h3>
            <p className="text-grey">
              We don’t pledge uninterrupted site access, protection against
              potential digital threats, or flawless functionality of third-party
              software linked to our platform.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>Modification</h3>
            <p className="text-grey">
              OUR BROKING Services reserves the prerogative to modify, halt, or
              withdraw any site information without prior intimation.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3 id="coin">Password Protection</h3>
            <p className="text-grey">
              Clients will be allocated unique browsing and transaction
              credentials. Periodic password alterations will be enforced to
              bolster security.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>Session Control</h3>
            <p className="text-grey">
              To ensure security, an idle period exceeding 20 minutes on our
              platform will prompt a re-login requirement.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>Internet Scanners and Intrusion Detection System </h3>
            <p className="text-grey">
              Our protective measures actively scan for potential digital threats,
              neutralizing suspicious activities to preserve the platform's
              sanctity.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>Indemnity</h3>
            <p className="text-grey">
              In the event of death or insolvency of the client, winding up or
              liquidation, or their otherwise becoming incapable of receiving and
              paying for or delivering or transferring securities which the client
              has ordered to be bought or sold, OUR BROKING may close out the
              transaction of the client and the client or his legal representative
              shall be liable for any losses, costs and be entitled to any surplus
              which may result therefrom.
            </p>
          </div>
        </section>
        <section className="static-sections">
          <div className="mini-container">
            <h3>Notice</h3>
            <p className="text-grey">
              Accessing this platform means you fully endorse and commit to these
              terms &amp; conditions. Ensure that a legal expert reviews your
              Terms &amp; Conditions to tailor it appropriately for your business,
              taking into account any legal stipulations pertinent to your
              jurisdiction.
            </p>
          </div>
        </section>
      </main>
    </main>
    {/* scripts */}
    {/* Page specific scripts */}
    <noscript>
      &lt;p&gt; &lt;img src="//matomo.OUR
      BROKING.net/matomo.php?idsite=3&amp;amp;rec=1" style="border: 0" alt=""
      /&gt;&lt;/p &gt;
    </noscript>
  </section>
   );
}

export default Tnc;